import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×6\\@75% 1RM`}</p>
    <p>{`Glute Ham Raise 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`10-Back Squats (225/155)(RX+ 275/185)`}</p>
    <p>{`50-Situps`}</p>
    <p>{`15-Back Squats (185/125)(RX+ 225/155)`}</p>
    <p>{`50-Situps`}</p>
    <p>{`20-Back Squats (135/95)(RX+ 185/125)`}</p>
    <p>{`50-Situps`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      